@tailwind components;

@layer components {
  .custom-nav-link {
    @apply ml-2 mr-2 text-2xl md:ml-1 md:mr-3 md:text-xl lg:mr-8;
    transition: text-shadow 250ms ease-in-out;
  }

  .custom-popup-link {
    @apply text-2xl text-center py-2 rounded-md;
    transition: all 250ms ease-in-out;
  }

  .custom-nav-link:hover,
  .custom-popup-link:hover {
    @apply 
      underline 
      decoration-2 
      underline-offset-4;
  }

  .custom-popup-link:hover {
    @apply bg-white;
  }

  .custom-nav-btn {
    @apply w-0 rounded-full !bg-rward-yellow p-0 bg-opacity-0 hover:bg-opacity-100 hover:text-white transition-all duration-300 focus-visible:outline-none;
  }

  .custom-nav-btn[data-show="true"] {
    @apply w-auto p-2;
  }

  .custom-nav-btn[data-open="true"] {
    @apply !bg-rward-blue-dark;
  }

  .custom-nav-btn[data-open="false"] {
    @apply outline-slate-300 outline-1;
  }

  .custom-popup-btn {
    @apply rounded-full p-2 bg-slate-400 md:bg-slate-300 md:hover:bg-slate-400 transition-all duration-500;
  }

  .custom-popup-btn:hover > svg,
  .custom-popup-btn:hover {
    @apply stroke-white text-white;
  }

  .header-notif-list {
    @apply flex flex-col space-y-2 max-h-[calc(100vh-250px)] overflow-auto;
  }
  .header-notif-list[data-fetching="true"] {
    @apply overflow-hidden;
  }

  .header-notif-item {
    @apply flex flex-row rounded-md hover:bg-slate-500 bg-slate-200 p-2;
  }

  .header-notif-item-texts {
    @apply flex flex-col justify-start items-start;
  }

  .notification-bell[data-has-notif="true"] {
    animation: ring-bell 4s ease-in-out infinite;
  }

  @keyframes ring-bell {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }
}