@tailwind components;

@layer components {
  .account-page {
    @apply h-[calc(100vh-5rem)] py-8 flex flex-col justify-center items-center w-full overflow-hidden;
  }

  .account-container {
    @apply bg-white rounded-lg w-[calc(100%-4rem)] p-6 flex flex-row justify-start max-w-7xl;
  }
}