@tailwind components;

@layer components {
  li.slick-active .custom-paging {
    @apply bg-rward-yellow;
  }
  
  .custom-paging {
    @apply rounded-full w-6 h-6 bg-rward-blue-dark flex-1 text-sm text-white disabled:bg-gray-300;
  }
}