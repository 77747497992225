@tailwind base;
@tailwind components;

@layer base {
  .form-page {
    @apply h-full flex flex-col justify-center items-center w-full;
  }

  .form-container {
    @apply bg-white shadow-xl rounded-lg w-full p-6 flex flex-col justify-start my-8;
  }

  .form-title {
    @apply text-center text-2xl mb-4;
  }

}

@layer components {
  .form-page-container {
    @apply w-[calc(100%-20px)] md:max-w-lg;
  }

  .form-field {
    @apply flex flex-col justify-start items-start space-y-2;
  }

  .form-field-checkbox {
    @apply form-checkbox w-full;
  }

  .form-field-textarea {
    @apply form-textarea w-full min-h-[42px] disabled:bg-gray-200;
  }

  .form-field-input {
    @apply form-input w-full disabled:bg-gray-200;
  }

  .form-field-select {
    @apply form-select w-full;
  }

  .form-field-multiselect {
    @apply form-select w-full;
  }

  .form-field-error {
    @apply text-red-400 text-sm h-6;
  }

  .form-field-error[data-has-validation='false'] {
    @apply h-2;
  }

  .form-submit {
    @apply !bg-blue-400 text-white rounded-lg px-3 py-1 hover:shadow-2xl disabled:!bg-gray-400 self-end mt-4;
  }

  .form-field-react-select__container {
    @apply w-full;
  }
  
  .form-field-react-select__container .form-field-react-select__control {
    @apply border-[#6b7280] rounded-lg;
  }

  .form-field-react-select__container[data-rounded-full='true'] .form-field-react-select__control {
    @apply rounded-full;
  }

  .form-field-react-select__container .form-field-react-select__input:focus {
    box-shadow: none !important;
  }
  
  .form-field-react-select__container .form-field-react-select__menu {
    @apply w-full z-[999];
  }

  .form-field-switch {
    @apply !h-6 !w-10 !rounded-full !bg-slate-400 checked:!bg-blue-400 checked:hover:!bg-blue-600 disabled:!bg-slate-500 disabled:hover:!bg-slate-500 relative fill-transparent cursor-pointer;
    background-image: none !important;
  }

  .form-field-switch::before {
    @apply absolute top-1 left-1 h-[calc(100%-.5rem)] aspect-square rounded-full bg-white transition-transform;
    content: '';
  }

  .form-field-switch:checked::before {
    @apply translate-x-full;
  }
}