@tailwind base;
@tailwind components;

@layer base {
  .info-page {
    @apply h-[calc(100vh-5rem)] py-8 flex flex-col justify-center items-center w-full overflow-hidden;
  }

  .info-container {
    @apply bg-white rounded-lg w-[calc(100%-4rem)] p-6 flex flex-col justify-start max-w-7xl;
  }
}

@layer components {
  .tabbed-info {
    @apply h-full lg:flex-row overflow-hidden;
  }
  
  .tabbed-info-tabs {
    @apply lg:flex-col lg:w-1/5 lg:mb-0 space-y-0 lg:space-y-2;
  }

  .tabbed-info-panels {
    @apply h-[calc(100%-3rem)] lg:h-full lg:flex-col lg:w-4/5;
  }

  .tabbed-info-panel {
    @apply flex flex-col justify-center items-center w-full h-full overflow-hidden;
  }
}