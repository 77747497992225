@tailwind components;
@tailwind base;

@layer base {
  .card {
    @apply bg-white rounded-xl p-4 sm:p-6 shadow-lg relative;
  }

  .card-details {
    @apply flex flex-col justify-between h-1/2 pt-4;
  }

  .card-data {
    @apply flex flex-col justify-start items-start;
  }

  .card-data-title {
    @apply text-lg;
  }

  .card-data-desc {
    @apply text-sm text-left w-full sm:mt-2;
  }

  .card-actions {
    @apply flex flex-row justify-end items-center md:hidden group-hover:flex space-x-2;
  }

  .card-btn {
    @apply p-3 md:p-2 rounded-full bg-slate-500 md:bg-transparent md:hover:bg-slate-500 h-12 w-12 sm:h-10 sm:w-10 md:h-8 md:w-8 text-white md:text-black hover:text-white;
  }

  .card-btn[data-danger="true"] {
    @apply bg-red-400 md:bg-transparent md:hover:bg-red-400;
  }
}

@layer components {
  .restaurant-card,
  .branch-card,
  .voucher-card, {
    @apply aspect-[3/4] w-full sm:w-[calc(50%-.5rem)] md:w-[calc(33.33%-.6666667rem)] lg:w-[calc(25%-.75rem)] xl:w-[calc(100%/6-.8333333rem)] transition-all hover:-translate-y-3 hover:shadow-2xl;
  }
  .redemption-card {
    @apply  w-full sm:w-[calc(50%-.5rem)] lg:w-[calc(33.33%-.6666667rem)] xl:w-[calc(25%-.75rem)] 2xl:w-[calc(100%/6-.8333333rem)] transition-all hover:-translate-y-3 hover:shadow-2xl !p-2 sm:!p-4 aspect-[9/10] xs:aspect-[3/4];
  }

  .restaurant-card-img,
  .branch-card-img,
  .voucher-card-img {
    @apply w-full h-1/2 object-contain;
  }

  .perks-card-container,
  .contract-card-container {
    @apply w-full md:w-1/2 px-2;
  }

  .voucher-redeem-card-container {
    @apply w-full sm:w-1/2 md:w-1/3 px-2 py-2;
  }

  .perks-card,
  .contract-card,
  .voucher-redeem-card {
    @apply w-full flex flex-col drop-shadow-2xl transition-all hover:-translate-y-3 hover:shadow-2xl;
  }

  .voucher-redeem-card {
    @apply cursor-pointer;
  }
}