@import './main.css';
@import './home.css';
@import './header.css';
@import './item-list.css';
@import './card.css';
@import './form.css';
@import './information.css';
@import './account.css';
@import './nested-checkbox.css';
@import './react-slick.css';
@import './print.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
