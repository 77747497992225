@tailwind components;
@tailwind base;

@import url('https://use.typekit.net/bzq5pcb.css');

@layer base {
  @font-face {
    font-family: 'LequireRounded';
    src: local('LequireRounded'), url('./assets/fonts/Lequire Rounded.ttf') format("truetype");
  }
  .main-content {
    @apply pt-20 transition-colors;
  }

  .main-content[data-landing='false'] {
    @apply bg-slate-200 pt-16;
  }

  * {
    @apply font-sans-serif;
  }
}

@layer components {
  .home-links {
    @apply hidden md:flex hover:bg-rward-yellow hover:text-white px-4 py-2 rounded-full transition-all;
  }
  
  .custom-logo {
    @apply mx-8 md:mx-6;
  }

  .custom-button {
    @apply 
      whitespace-nowrap 
      inline-flex 
      items-center 
      justify-center 
      px-10
      py-2
      border 
      border-transparent 
      bg-rward-yellow
      rounded-full 
      shadow-sm 
      text-black
      text-xl 
      opacity-75
      enabled:hover:opacity-100
      disabled:hover:opacity-75;
  }

  .custom-button[data-loading="true"] {
    @apply !pr-0;
  }

  .custom-button-arrow {
    @apply 
      ml-4 
      h-10
      w-10
      text-black
      inline-block
      mix-blend-multiply;
  }

  .custom-button-spin {
    @apply 
      !mx-4
      h-10
      w-10
      text-black
      inline-block;
  }


  .alert-box {
    @apply rounded-lg py-2 px-3 md:py-5 md:px-6 mb-3 text-base flex flex-col w-full md:opacity-75 md:hover:opacity-100;
    animation: slide-from-right 500ms ease-in-out;
  }
  
  @keyframes fade {
  
    30% {
      opacity: 1;
    }
  
    90% {
      opacity: 0;
    }
  
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-from-right {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    25% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  .tab-list {
    @apply flex space-x-1 md:space-y-1 md:space-x-0 rounded-xl bg-blue-900/20 p-1 mb-4;
  }

  .tab-list-item {
    @apply w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 relative ;
  }

  .tab-list-item-selected {
    @apply !bg-white shadow;
  }

  .tab-list-item-unselected {
    @apply text-black hover:bg-white/[0.12] hover:text-blue-400;
  }
}