@tailwind base;

@layer base {
  .print-doc {
    @apply hidden print:block w-full h-full relative;
  }

  .print-content {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 p-4 px-8 rounded-md mt-64 bg-rward-blue-light flex flex-col justify-center items-center;
  }
}