@tailwind base;
@tailwind components;

@layer base {
  .collection-list-content {
    @apply flex flex-col p-6 w-full;
  }

  .item-list-container {
    @apply flex flex-col justify-start;
  }

  .item-list-header {
    @apply flex flex-col md:flex-row justify-between items-center px-4 py-2;
  }

  .item-list-actions {
    @apply flex flex-row justify-start space-x-4 mb-4 space-y-2 md:space-y-0 flex-wrap;
  }

  .item-list-filters {
    @apply flex flex-row justify-end;
  }

  .item-list-items {
    @apply flex flex-row flex-wrap gap-4;
  }
  
  .item-list-loader {
    @apply w-full px-4 py-10 flex justify-center items-center;
  }

  .item-list-zero {
    @apply w-full py-16 px-4 text-center text-4xl;
  }

  .item-list-footer {
    @apply flex flex-col md:flex-row justify-between items-center px-4 py-6 space-y-4 md:space-y-0;
  }

  .item-list-rows {
    @apply flex flex-col justify-start md:flex-row md:justify-end items-center mb-6 md:mt-6 space-y-2 md:space-y-0;
  }
  
  .item-list-pages {
    @apply flex md:flex flex-col justify-start md:flex-row items-center;
  }

  .item-list-pages-btns {
    @apply flex flex-row justify-center items-center space-x-2 pt-4 md:pt-0 md:ml-4;
  }

  .item-list-page-btn {
    @apply w-8 h-8 bg-slate-400 hover:bg-slate-600 text-white rounded-full flex flex-col justify-center items-center shadow-md;
  }

  .item-list-page-btn[data-selected="true"] {
    @apply bg-white text-slate-400 shadow-xl;
  }

  .item-list-action-btn-text {
    @apply text-sm md:text-lg uppercase;
  }
}

@layer components {
  .item-list-action-btn {
    @apply flex flex-row rounded-lg bg-rward-yellow text-white px-2 py-1 md:px-6 md:py-3 items-center;
  }
}