@tailwind base;

@layer base {
  .nested-checkbox-input {
    @apply form-checkbox appearance-none 
    h-4 w-4 border border-gray-300 rounded-md p-2
    bg-white checked:bg-blue-600 
    checked:border-blue-600 focus:outline-none 
    transition duration-200 my-1 align-top 
    bg-no-repeat bg-center bg-contain float-left 
    cursor-pointer ml-2;
  }
  
  .nested-checkbox-input:disabled:checked,
  .nested-checkbox-input:disabled:indeterminate {
    @apply bg-slate-600 border-slate-600;
  }

  ul.nested-checkbox {
    @apply space-y-2 mx-2;
  }

  ul.nested-checkbox > li {
    @apply space-y-2 transition-all duration-300;
  }

  ul.nested-checkbox > li > button {
    @apply flex flex-row justify-start items-center rounded-lg px-2 py-1 w-full hover:bg-blue-200;
  }

  ul.nested-checkbox > li > button[data-selected='true'] {
    @apply bg-blue-400 text-white hover:bg-blue-500;
  }

  ul.nested-checkbox > li > button > input[type='checkbox'] {
    @apply nested-checkbox-input;
  }

  ul.nested-checkbox > li > ul {
    @apply ml-6;
  }

  ul.nested-checkbox > li > ul > li {
    @apply flex flex-row justify-start items-center px-2 py-1 rounded-lg hover:bg-blue-200 cursor-pointer;
  }

  ul.nested-checkbox > li > ul > li[data-selected='true'] {
    @apply bg-blue-400 text-white hover:bg-blue-500;
  }

  ul.nested-checkbox > li > ul > li > input[type='checkbox'] {
    @apply nested-checkbox-input mr-2;
  }

}