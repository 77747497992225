@tailwind components;

@layer components {
  .card-1 {
    @apply absolute top-[-1250px] left-[200px] pointer-events-none -z-10;
    animation: card1-movement 2.5s ease-in-out infinite;
  }

  .card-1 > div {
    @apply bg-rward-blue-light h-[1024px] w-[768px] rounded-[50px];
    transform: skew(-60deg, 45deg);
  }
  
  @keyframes card1-movement {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  .artwork-1 {
    animation: artwork-1-anim 5s ease-in-out infinite;
  }

  .artwork-2 {
    animation: artwork-2-anim 5s ease-in-out infinite;
  }
  .artwork-3 {
    animation: artwork-3-anim 5s ease-in-out infinite;
  }
  .coin-1 {
    animation: coin-1-anim 6s ease-in-out infinite;
  }
  .coin-2 {
    animation: coin-2-anim 5s ease-in-out infinite;
  }
  .coin-3 {
    animation: coin-3-anim 4s ease-in-out infinite;
  }
  .coin-4 {
    animation: coin-4-anim 6s ease-in-out infinite;
  }

  .how-it-works-step {
    @apply font-rounded text-3xl mb-8 relative;
  }

  .how-it-works-step::after {
    content: "";
    @apply absolute right-0 top-0 h-full w-[55%] border-2 border-solid border-rward-yellow translate-x-[18%] translate-y-[-.3rem];
  }

  .how-it-works-step-2::after {
    @apply border-rward-blue-light w-[82%] translate-x-[20%] ;
  }

  .how-it-works-step-3::after {
    @apply border-rward-red;
  }

  .waiting-list-coin {
    @apply absolute pointer-events-none bg-contain;
  }










  @keyframes artwork-1-anim {
    0% {
      transform: translateY(-50%);
    }
    50% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-50%);
    }
  }

  @keyframes artwork-2-anim {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-50%, 20%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes artwork-3-anim {
    0% {
      transform: translate(-50%, 20%);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50%, 20%);
    }
  }
  @keyframes coin-1-anim {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes coin-2-anim {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-40%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes coin-3-anim {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-50%, 50%);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes coin-4-anim {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50%);
    }
    100% {
      transform: translateY(0);
    }
  }
}